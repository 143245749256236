import { c as _c } from "react-compiler-runtime";
import { HHBox, HHCircularProgress, HingeHealthSecurity } from '@hinge-health/react-component-library';
import GlobalStyles from '@mui/material/GlobalStyles';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { createContext, Dispatch, SetStateAction, Suspense, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Apollo, AuthenticatedPage } from './components';
import { STYLE_SEED, STYLE_SEED_OVERRIDE } from './constants/strings/style-seed';
import { AdminContextProvider } from './contexts/admin.context';
import FeatureFlagProvider from './contexts/feature-flag-provider';
import { OktaAdminRoleProvider } from './contexts/okta-admin-role.context.tsx';
import { SseProvider } from './contexts/sse.context';
import i18n from './i18n';
import { NotFound } from './pages/not-found';
import { authRoutes, getAllRoutes, RouteDetails } from './routes';
import { isStage } from './utils/environment';
LicenseInfo.setLicenseKey((process.env.MUI_X_PRO_LICENSE_KEY as string));
const styles = {
  global: {
    body: {
      margin: 0
    }
  },
  containerStyle: {
    display: 'flex',
    width: '100%',
    height: '100%'
  }
};
const globalStyles = <GlobalStyles styles={styles.global} />;
export const PtContext = createContext<[string, Dispatch<SetStateAction<string>>?]>(['']);
export const PtContextProvider = t0 => {
  const $ = _c(5);
  const {
    children
  } = t0;
  const [ptContext, setPtContext] = useState("default");
  let t1;
  if ($[0] !== ptContext) {
    t1 = [ptContext, setPtContext];
    $[0] = ptContext;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] !== t1 || $[3] !== children) {
    t2 = <PtContext.Provider value={t1}>{children}</PtContext.Provider>;
    $[2] = t1;
    $[3] = children;
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  return t2;
};
const AuthenticatedComponent = t0 => {
  const $ = _c(5);
  const {
    route
  } = t0;
  let t1;
  if ($[0] !== route.component) {
    t1 = <route.component />;
    $[0] = route.component;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] !== route.hideSideNavigation || $[3] !== t1) {
    t2 = <AuthenticatedPage hideSideNavigation={route.hideSideNavigation}>{t1}</AuthenticatedPage>;
    $[2] = route.hideSideNavigation;
    $[3] = t1;
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  return t2;
};
const renderRoute = (route: RouteDetails): JSX.Element => <Route key={route.path} path={route.path} element={<AuthenticatedComponent route={route} />}>
    {route.children.length > 0 ? route.children.map(nestedRoute => renderRoute(nestedRoute)) : null}
  </Route>;
const generateClassName = createGenerateClassName({
  productionPrefix: STYLE_SEED_OVERRIDE,
  seed: STYLE_SEED
});
const App = () => {
  const $ = _c(16);
  const navigate = useNavigate();
  let t0;
  if ($[0] !== navigate) {
    t0 = async originalUri => {
      localStorage.setItem("hasRedirectedToPT", "false");
      localStorage.setItem("hasRedirectedToCoach", "false");
      localStorage.setItem("hasRedirectedToOS", "false");
      navigate(originalUri || "/");
    };
    $[0] = navigate;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const restoreOriginalUri = t0;
  let t1;
  if ($[2] !== restoreOriginalUri) {
    t1 = {
      clientId: process.env.OKTA_CLIENT_ID || "",
      issuer: process.env.OKTA_ISSUER || "",
      redirectUri: `${window.location.origin}/okta/callback`,
      restoreOriginalUri
    };
    $[2] = restoreOriginalUri;
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  let t2;
  if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = {
      enabled: false
    };
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  let t3;
  if ($[5] !== t1) {
    t3 = {
      oktaConfig: t1,
      capabilitiesConfig: t2
    };
    $[5] = t1;
    $[6] = t3;
  } else {
    t3 = $[6];
  }
  const options = t3;
  let t4;
  if ($[7] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = () => {
      if (isStage) {
        const redirect = new URLSearchParams(window.location.search).get("redirect");
        if (redirect && !window.location.pathname.includes(redirect)) {
          window.location.href = `${window.location.origin}/${redirect}/`;
        }
      }
    };
    $[7] = t4;
  } else {
    t4 = $[7];
  }
  useEffect(t4);
  let t5;
  if ($[8] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = <AuthenticatedPage hideSideNavigation={true}><HHCircularProgress size={16} /></AuthenticatedPage>;
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  let t6;
  if ($[9] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = authRoutes.map(route => <Route key={route.path} path={route.path} element={<route.component />} />);
    $[9] = t6;
  } else {
    t6 = $[9];
  }
  let t7;
  if ($[10] === Symbol.for("react.memo_cache_sentinel")) {
    t7 = <Route index={true} key="index" element={<AuthenticatedComponent route={getAllRoutes()[0]} />} />;
    $[10] = t7;
  } else {
    t7 = $[10];
  }
  let t8;
  if ($[11] === Symbol.for("react.memo_cache_sentinel")) {
    t8 = getAllRoutes().map(route_0 => renderRoute(route_0));
    $[11] = t8;
  } else {
    t8 = $[11];
  }
  let t9;
  if ($[12] === Symbol.for("react.memo_cache_sentinel")) {
    t9 = <HHCircularProgress size={16} />;
    $[12] = t9;
  } else {
    t9 = $[12];
  }
  let t10;
  if ($[13] === Symbol.for("react.memo_cache_sentinel")) {
    t10 = <Apollo><StylesProvider generateClassName={generateClassName}><I18nextProvider i18n={i18n}><OktaAdminRoleProvider><PtContextProvider><HHBox sx={styles.containerStyle}><Suspense fallback={t5}><SseProvider><AdminContextProvider><FeatureFlagProvider><Routes>{t6}{t7}{t8}<Route path="*" element={<Suspense fallback={t9}><NotFound /></Suspense>} /></Routes></FeatureFlagProvider></AdminContextProvider></SseProvider></Suspense></HHBox></PtContextProvider></OktaAdminRoleProvider></I18nextProvider></StylesProvider></Apollo>;
    $[13] = t10;
  } else {
    t10 = $[13];
  }
  let t11;
  if ($[14] !== options) {
    t11 = <>{globalStyles}<HingeHealthSecurity options={options}>{t10}</HingeHealthSecurity></>;
    $[14] = options;
    $[15] = t11;
  } else {
    t11 = $[15];
  }
  return t11;
};
export default App;