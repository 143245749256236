import { c as _c } from "react-compiler-runtime";
import { ctpTokens, HHBox } from '@hinge-health/react-component-library';
import MembersHelpedDropdown from './components/members-helped';
import { MPE_TEST_ID } from './constants';
export const MpeDashboard = () => {
  const $ = _c(2);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: ctpTokens.palette.sand.shades["12p"]
    };
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  let t1;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = <HHBox sx={t0} data-testid={MPE_TEST_ID.MPE_DASHBOARD}><MembersHelpedDropdown /></HHBox>;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  return t1;
};