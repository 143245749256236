import { c as _c } from "react-compiler-runtime";
import { HHListItem, HHListItemText } from '@hinge-health/react-component-library';
import { Skeleton } from '@mui/material';

/**
 * Type for skeleton animation options
 */
export type SkeletonAnimation = 'pulse' | 'wave' | false;
export interface LoadingSkeletonProps {
  /**
   * Number of skeleton items to display
   * @default 3
   */
  count?: number;
  /**
   * Custom styles for the list item
   */
  listItemStyle?: React.CSSProperties;
  /**
   * Custom props for the primary skeleton
   */
  primarySkeletonProps?: {
    width?: string | number;
    height?: string | number;
    animation?: SkeletonAnimation;
  };
  /**
   * Custom props for the secondary skeleton
   */
  secondarySkeletonProps?: {
    width?: string | number;
    height?: string | number;
    animation?: SkeletonAnimation;
  };
  /**
   * Whether to show the action button skeleton
   * @default true
   */
  showActionButton?: boolean;
  /**
   * Custom props for the action button skeleton
   */
  actionButtonSkeletonProps?: {
    width?: string | number;
    height?: string | number;
    animation?: SkeletonAnimation;
  };
}
const defaultStyles = {
  loadingListItem: {
    backgroundColor: '#F5F5F5',
    marginBottom: '0.5rem',
    borderRadius: '0.25rem',
    radius: '0.5rem',
    padding: '1rem',
    gap: '0.75rem',
    borderBottom: '0.25px',
    height: '4.6875rem'
  }
};

/**
 * A customizable loading skeleton component for member lists
 */
export const LoadingSkeleton: React.FC<LoadingSkeletonProps> = t0 => {
  const $ = _c(43);
  let t1;
  let t2;
  let t3;
  let t4;
  let t5;
  let t6;
  let rest;
  if ($[0] !== t0) {
    ({
      count: t1,
      listItemStyle: t2,
      primarySkeletonProps: t3,
      secondarySkeletonProps: t4,
      showActionButton: t5,
      actionButtonSkeletonProps: t6,
      ...rest
    } = t0);
    $[0] = t0;
    $[1] = t1;
    $[2] = t2;
    $[3] = t3;
    $[4] = t4;
    $[5] = t5;
    $[6] = t6;
    $[7] = rest;
  } else {
    t1 = $[1];
    t2 = $[2];
    t3 = $[3];
    t4 = $[4];
    t5 = $[5];
    t6 = $[6];
    rest = $[7];
  }
  const count = t1 === undefined ? 3 : t1;
  const listItemStyle = t2 === undefined ? defaultStyles.loadingListItem : t2;
  let t7;
  if ($[8] !== t3) {
    t7 = t3 === undefined ? {
      width: "60%",
      height: 1.25,
      animation: "wave"
    } : t3;
    $[8] = t3;
    $[9] = t7;
  } else {
    t7 = $[9];
  }
  const primarySkeletonProps = t7;
  let t8;
  if ($[10] !== t4) {
    t8 = t4 === undefined ? {
      width: "80%",
      height: 1,
      animation: "wave"
    } : t4;
    $[10] = t4;
    $[11] = t8;
  } else {
    t8 = $[11];
  }
  const secondarySkeletonProps = t8;
  const showActionButton = t5 === undefined ? true : t5;
  let t9;
  if ($[12] !== t6) {
    t9 = t6 === undefined ? {
      width: 1.5,
      height: 1.5,
      animation: "wave"
    } : t6;
    $[12] = t6;
    $[13] = t9;
  } else {
    t9 = $[13];
  }
  const actionButtonSkeletonProps = t9;
  let t10;
  if ($[14] !== count || $[15] !== listItemStyle || $[16] !== rest || $[17] !== primarySkeletonProps.width || $[18] !== primarySkeletonProps.height || $[19] !== primarySkeletonProps.animation || $[20] !== secondarySkeletonProps.width || $[21] !== secondarySkeletonProps.height || $[22] !== secondarySkeletonProps.animation || $[23] !== showActionButton || $[24] !== actionButtonSkeletonProps.width || $[25] !== actionButtonSkeletonProps.height || $[26] !== actionButtonSkeletonProps.animation) {
    let t11;
    if ($[28] !== listItemStyle || $[29] !== rest || $[30] !== primarySkeletonProps.width || $[31] !== primarySkeletonProps.height || $[32] !== primarySkeletonProps.animation || $[33] !== secondarySkeletonProps.width || $[34] !== secondarySkeletonProps.height || $[35] !== secondarySkeletonProps.animation || $[36] !== showActionButton || $[37] !== actionButtonSkeletonProps.width || $[38] !== actionButtonSkeletonProps.height || $[39] !== actionButtonSkeletonProps.animation) {
      t11 = (_, _index) => {
        const skeletonId = `loading-skeleton-${Math.random().toString(36).substring(2, 11)}`;
        return <HHListItem key={skeletonId} style={listItemStyle} {...rest}><HHListItemText primary={<Skeleton width={primarySkeletonProps.width} height={primarySkeletonProps.height} animation={primarySkeletonProps.animation} data-testid="primary-skeleton" />} secondary={<Skeleton width={secondarySkeletonProps.width} height={secondarySkeletonProps.height} animation={secondarySkeletonProps.animation} data-testid="secondary-skeleton" />} />{showActionButton && <Skeleton variant="circular" width={actionButtonSkeletonProps.width} height={actionButtonSkeletonProps.height} animation={actionButtonSkeletonProps.animation} data-testid="action-button-skeleton" />}</HHListItem>;
      };
      $[28] = listItemStyle;
      $[29] = rest;
      $[30] = primarySkeletonProps.width;
      $[31] = primarySkeletonProps.height;
      $[32] = primarySkeletonProps.animation;
      $[33] = secondarySkeletonProps.width;
      $[34] = secondarySkeletonProps.height;
      $[35] = secondarySkeletonProps.animation;
      $[36] = showActionButton;
      $[37] = actionButtonSkeletonProps.width;
      $[38] = actionButtonSkeletonProps.height;
      $[39] = actionButtonSkeletonProps.animation;
      $[40] = t11;
    } else {
      t11 = $[40];
    }
    t10 = Array.from({
      length: count
    }).map(t11);
    $[14] = count;
    $[15] = listItemStyle;
    $[16] = rest;
    $[17] = primarySkeletonProps.width;
    $[18] = primarySkeletonProps.height;
    $[19] = primarySkeletonProps.animation;
    $[20] = secondarySkeletonProps.width;
    $[21] = secondarySkeletonProps.height;
    $[22] = secondarySkeletonProps.animation;
    $[23] = showActionButton;
    $[24] = actionButtonSkeletonProps.width;
    $[25] = actionButtonSkeletonProps.height;
    $[26] = actionButtonSkeletonProps.animation;
    $[27] = t10;
  } else {
    t10 = $[27];
  }
  let t11;
  if ($[41] !== t10) {
    t11 = <>{t10}</>;
    $[41] = t10;
    $[42] = t11;
  } else {
    t11 = $[42];
  }
  return t11;
};
export default LoadingSkeleton;