import { ctpTokens, HHAccordion, HHAccordionDetails, HHAccordionSummary, HHBox, HHCard, HHList, HHTextField, Typography } from '@hinge-health/react-component-library';
import { ExpandMore } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useEffect, useRef, useState } from 'react';
import partyPopper from '../assets/lottie/party-popper.json';
import { LoadingSkeleton } from './loading-skeleton';
import MemberList, { Member } from './member-list';

// TODO: Replace with actual data from API https://hingehealth.atlassian.net/browse/MERU-4203
const members: Member[] = [{
  id: '1',
  name: 'Brittany Wilson',
  completeAction: 'Message sent: 6:12pm • 12/6/24'
}, {
  id: '2',
  name: 'Katherine Brown',
  completeAction: 'Message sent: 6:12pm • 12/6/24'
}, {
  id: '3',
  name: 'Samantha Lewis',
  completeAction: 'Message sent: 6:12pm • 12/6/24'
}, {
  id: '4',
  name: 'Ashley Davis',
  completeAction: 'Message sent: 6:12pm • 12/6/24'
}, {
  id: '5',
  name: 'Laura Miller',
  completeAction: 'Message sent: 6:12pm • 12/6/24'
}, {
  id: '6',
  name: 'Nicole Anderson',
  completeAction: 'Message sent: 6:12pm • 12/6/24'
}, {
  id: '7',
  name: 'Emily Johnson',
  completeAction: 'Message sent: 6:12pm • 12/6/24'
}, {
  id: '8',
  name: 'Rachel Martinez',
  completeAction: 'Message sent: 6:12pm • 12/6/24'
}];
const styles = {
  card: {
    width: '25rem',
    height: 'auto'
  },
  accordionStyle: {
    width: '25rem'
  },
  lottie: {
    height: '1.5rem',
    width: '1.5rem',
    marginRight: '0.375rem'
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  loadingListItem: {
    backgroundColor: '#F5F5F5',
    marginBottom: '0.5rem',
    borderRadius: '0.25rem',
    radius: '0.5rem',
    padding: '1rem',
    gap: '0.75rem',
    borderBottom: '0.25px',
    height: '4.6875rem'
  },
  searchIcon: {
    color: ctpTokens.palette.neutral[10],
    marginRight: '0.625rem'
  },
  titleText: {
    marginTop: '0.25rem'
  }
};
const MembersHelpedDropdown: React.FC = () => {
  const [expanded, setExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [displayMembers, setDisplayMembers] = useState<Member[]>(members);
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (lottieRef.current) {
        lottieRef.current.play();
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    // Simulate data loading when search query changes
    const performSearch = async (): Promise<void> => {
      if (searchQuery) {
        setIsLoading(true);
        try {
          // Simulate API delay
          await new Promise(resolve => setTimeout(resolve, 1000));
          const filtered = members.filter(member => member.name.toLowerCase().includes(searchQuery.toLowerCase()));
          setDisplayMembers(filtered);
        } catch (error) {
          console.error('Error searching members:', error);
          setDisplayMembers([]);
        } finally {
          setIsLoading(false);
        }
      } else {
        setDisplayMembers(members);
      }
    };
    performSearch();
  }, [searchQuery]);
  const toggleExpand = (): void => {
    setExpanded(!expanded);
  };
  return <>
      <HHCard style={styles.card}>
        <HHAccordion style={styles.accordionStyle} expanded={expanded}>
          <HHAccordionSummary expandIcon={<ExpandMore />} onClick={toggleExpand} sx={{
          display: 'flex'
        }}>
            <Lottie lottieRef={lottieRef} animationData={partyPopper} loop={false} autoplay={false} data-testid="party-popper-id" style={styles.lottie} />
            <Typography variant="h4" sx={styles.titleText}>
              40 Members Helped
            </Typography>
          </HHAccordionSummary>
          <HHAccordionDetails>
            <HHBox style={styles.flexContainer}>
              <HHTextField fullWidth placeholder="Search member name or ID" hhVariant={'outlined'} value={searchQuery} onChange={(e): void => setSearchQuery(e.target.value)} InputLabelProps={{
              shrink: false
            }} InputProps={{
              endAdornment: <InputAdornment position="end" sx={styles.searchIcon}>
                      <SearchIcon />
                    </InputAdornment>
            }} />
            </HHBox>
            {isLoading ? <HHList>
                <LoadingSkeleton count={3} listItemStyle={styles.loadingListItem} primarySkeletonProps={{
              width: '70%',
              height: 20,
              animation: 'wave'
            }} secondarySkeletonProps={{
              width: '50%',
              height: 16,
              animation: 'wave'
            }} />
              </HHList> : <MemberList members={displayMembers} />}
          </HHAccordionDetails>
        </HHAccordion>
      </HHCard>
    </>;
};
export default MembersHelpedDropdown;