import { c as _c } from "react-compiler-runtime";
import { ctpTokens, HHBox, HHList, HHListItem, HHListItemText, Typography } from '@hinge-health/react-component-library';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton } from '@mui/material';
export interface Member {
  id: string;
  name: string;
  completeAction: string;
}
export interface MemberListProps {
  members: Member[];
}
const styles = {
  listItem: {
    backgroundColor: '#CFB9911F',
    marginBottom: '0.5rem',
    borderRadius: '0.25rem',
    radius: '0.5rem',
    padding: '1rem',
    gap: '0.75rem',
    borderBottom: '0.25px',
    height: '4.6875rem'
  },
  loadingListItem: {
    backgroundColor: '#F5F5F5',
    marginBottom: '0.5rem',
    borderRadius: '0.25rem',
    radius: '0.5rem',
    padding: '1rem',
    gap: '0.75rem',
    borderBottom: '0.25px',
    height: '4.6875rem'
  },
  noMembersContainer: {
    py: 3,
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  noMembersText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.1px',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: '#676F6D'
  },
  primaryTypography: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '100%',
    letterSpacing: '1%'
  },
  secondaryTypography: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.1px',
    marginTop: '0.25rem'
  },
  checkIcon: {
    color: ctpTokens.palette.neutral[10]
  }
};
const MemberList: React.FC<MemberListProps> = t0 => {
  const $ = _c(2);
  const {
    members
  } = t0;
  let t1;
  if ($[0] !== members) {
    t1 = <>{members.length === 0 ? <HHBox sx={styles.noMembersContainer}><Typography sx={styles.noMembersText}>No members found</Typography></HHBox> : <HHList>{members.map(member => <HHListItem key={member.id} style={styles.listItem}><HHListItemText primary={member.name} primaryTypographyProps={styles.primaryTypography} secondary={member.completeAction} secondaryTypographyProps={styles.secondaryTypography} /><IconButton><CheckIcon sx={styles.checkIcon} /></IconButton></HHListItem>)}</HHList>}</>;
    $[0] = members;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  return t1;
};
export default MemberList;